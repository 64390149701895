import React from 'react';
import { GlobalStyles } from '@mui/material';
import { NuqsAdapter } from 'nuqs/adapters/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { Router } from './Router';
import { AuthProvider } from './AuthProvider';

const theme = createTheme({
  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  palette: {
    primary: {
      main: '#2aafbb',
      contrastText: '#fff',
    },
    secondary: {
      main: '#EB8382',
      contrastText: '#fff',
    },
    text: {
      primary: '#303F56',
    },
  },
});

const App = () => (
  <StyledEngineProvider injectFirst>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            '@keyframes mui-auto-fill': { from: { display: 'block' } },
            '@keyframes mui-auto-fill-cancel': { from: { display: 'block' } },
          }}
        />
        <AuthProvider>
          <NuqsAdapter>
            <Router />
          </NuqsAdapter>
        </AuthProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </StyledEngineProvider>
);

export default App;
