import React from 'react';

import { Loadable } from '../../components/navigation/Loadable';

/**
 * @satisfies {ExtendedRouteObject[]}
 */
export const unverifiedUserRoutes = [
  {
    name: 'Request Verification',
    path: 'request-verification',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../components/login/auth-landing-pages/request-verification'))),
  },
];
