import React from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { Grid2 as Grid, Typography, Button, Link } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import AgreementBanner from '../../account-details/legal-section/AgreementBanner';
import { FORM_STATUS } from '../../../utils/constants';

const PREFIX = 'StatusCardPresentation';

const classes = {
  section: `${PREFIX}-section`,
  formHeading: `${PREFIX}-formHeading`,
  paper: `${PREFIX}-paper`,
  paperHolder: `${PREFIX}-paperHolder`,
  cancelButtonHolder: `${PREFIX}-cancelButtonHolder`,
  cancelButton: `${PREFIX}-cancelButton`,
  button: `${PREFIX}-button`,
  gridItem: `${PREFIX}-gridItem`,
  divider: `${PREFIX}-divider`,
  link: `${PREFIX}-link`,
  textButton: `${PREFIX}-textButton`,
  capitalizedHeading: `${PREFIX}-capitalizedHeading`,
  textUpload: `${PREFIX}-textUpload`,
};

const Root = styled('div')(() => ({
  width: '100%',
  [`& .${classes.section}`]: {
    marginBottom: 50,
  },

  [`& .${classes.formHeading}`]: {
    textTransform: 'capitalize',
  },

  [`& .${classes.paper}`]: {
    position: 'relative',
    padding: '20px',
    marginTop: '20px',
    width: '100%',
  },

  [`& .${classes.paperHolder}`]: {
    display: 'flex',
    alignItems: 'stretch',
    width: '100%',
  },

  [`& .${classes.cancelButtonHolder}`]: {
    position: 'relative',
  },

  [`& .${classes.cancelButton}`]: {
    height: 50,
    position: 'absolute',
    right: 0,
    top: 0,
  },

  [`& .${classes.button}`]: {
    position: 'absolute',
    bottom: 20,
  },

  [`& .${classes.gridItem}`]: {
    height: 30,
  },

  [`& .${classes.divider}`]: {
    marginBottom: 10,
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
  },

  [`& .${classes.textButton}`]: {
    padding: '10px 0px 0px 0px',
  },

  [`& .${classes.capitalizedHeading}`]: {
    textTransform: 'capitalize',
  },

  [`& .${classes.textUpload}`]: {
    padding: '5px 10px 5px 10px',
    height: '100%',
    display: 'inline-flex',
  },
}));

const { AWAITING_REVIEW } = FORM_STATUS;

const StatusCardPresentation = (props) => {
  const { statusObj, consultPaymentButton, clearForm, pendingTreatments, startOverdueCheckup, payPriceDifference } =
    props;
  const navigate = useNavigate();

  switch (statusObj.title) {
    case 'awaiting identification':
      return (
        <Root>
          <Typography variant="body1">
            Your consult is complete. You will need to upload your photo ID before a Candor GP can review your answers.
          </Typography>
          <br />
          <Grid className={classes.gridItem}>
            <Button className={classes.button} variant="contained" color="primary" href={statusObj.link}>
              Upload Photo Id
            </Button>
          </Grid>
        </Root>
      );
    case 'awaiting booking payment':
      return (
        <Root>
          <Typography variant="h6" className={classes.capitalizedHeading} gutterBottom>
            {statusObj.formName}
          </Typography>
          <Typography variant="body1">
            You previously cancelled your appointment. You will need to pay a booking fee to reschedule.
          </Typography>
          <br />
          <Grid className={classes.gridItem}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => consultPaymentButton(statusObj.stripePrice, statusObj.formName)}
            >
              Booking Payment
            </Button>
          </Grid>
        </Root>
      );
    case 'awaiting substitution fee':
      return (
        <Root>
          <Typography variant="h6" className={classes.capitalizedHeading} gutterBottom>
            Payment Required
          </Typography>
          <Typography variant="body1">
            Your pharmacy has substituted your order with a product that is more expensive than the original.
            <br />
            <br />
            If you do not wish to pay the difference please advice the pharmacy and they will refund your order.
          </Typography>
          <br />
          <Grid className={classes.gridItem}>
            <Button className={classes.button} variant="contained" color="primary" onClick={() => payPriceDifference()}>
              Pay Difference
            </Button>
          </Grid>
        </Root>
      );
    case 'awaiting payment':
      return (
        <Root>
          <Typography variant="h6" className={classes.capitalizedHeading} gutterBottom>
            {statusObj.formName}
          </Typography>
          <Typography variant="body1">
            Your consult is complete. You will need to pay your consultation fee before your Candor GP can review your
            answers.
          </Typography>
          <br />
          <Grid className={classes.gridItem}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => consultPaymentButton(statusObj.stripePrice, statusObj.formName)}
            >
              Make Payment
            </Button>
          </Grid>
        </Root>
      );
    case AWAITING_REVIEW:
    case 'awaiting script':
      return (
        <Root>
          <Typography variant="h6" className={classes.capitalizedHeading} gutterBottom>
            {statusObj.formName}
          </Typography>
          <Typography variant="body1">
            Thank you for submitting your consult. We are currently reviewing your responses. Our nursing team will
            reach out in the message section for any additional information they may need prior to passing you to a
            doctor.
          </Typography>
        </Root>
      );
    case 'awaiting schedule':
      return (
        <Root>
          <Typography variant="h6" className={classes.capitalizedHeading} gutterBottom>
            {statusObj.formName}
          </Typography>
          <Typography variant="body1">
            Click below to schedule your phonecall with your Candor health professional.
          </Typography>
          <br />
          <Grid className={classes.gridItem}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => navigate('/appointments')}
            >
              Appointments
            </Button>
          </Grid>
        </Root>
      );
    case 'awaiting upload':
      return (
        <Root>
          <Typography variant="h6" className={classes.capitalizedHeading} gutterBottom>
            {statusObj.formName}
          </Typography>
          <Typography variant="body1">
            Upload your referral letter or health summary as a PDF. If you&apos;re not able to obtain either let your GP
            know through the messages tab.
          </Typography>
          <br />
          <Grid className={classes.gridItem}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => navigate('/upload-supporting-documents')}
            >
              Upload Document
            </Button>
          </Grid>
        </Root>
      );
    case 'checkup required':
      return (
        <Root>
          <Typography variant="h6" gutterBottom>
            Checkup Required
          </Typography>
          <Typography variant="body1">You need to complete a quick checkup form.</Typography>
          <br />
          <Grid className={classes.gridItem}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => navigate('/checkup-form')}
            >
              Complete Checkup
            </Button>
          </Grid>
        </Root>
      );
    case 'no response':
      return (
        <Root>
          <Typography variant="h6" gutterBottom>
            Checkup Overdue
          </Typography>
          <Typography variant="body1">
            Your checkup is overdue, please complete as soon as possible to avoid interruption of your treatment.
          </Typography>
          <br />
          <Grid className={classes.gridItem}>
            <Button className={classes.button} variant="contained" color="primary" onClick={startOverdueCheckup}>
              Complete Checkup
            </Button>
          </Grid>
        </Root>
      );
    case 'in progress':
      return (
        <Root>
          <Typography variant="h6" className={classes.capitalizedHeading} gutterBottom>
            {statusObj.formName}
          </Typography>
          {statusObj.link !== 'checkup' && pendingTreatments && (
            <Button className={classes.cancelButton} onClick={() => clearForm(statusObj.formName)}>
              <CancelRoundedIcon color="primary" />
            </Button>
          )}
          <Typography variant="body1">This consult was started earlier. Click below to finish your consult.</Typography>
          <br />
          <Grid className={classes.gridItem}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => navigate(statusObj.link)}
            >
              Finish consult
            </Button>
          </Grid>
        </Root>
      );
    case 'catalogue updated':
      return (
        <Root>
          <Typography variant="h6" className={classes.capitalizedHeading} gutterBottom>
            {statusObj.formName}
          </Typography>
          <Typography variant="body1">Your Candor practitioner has added new items to your catalogue.</Typography>
          <br />
          <Grid className={classes.gridItem}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => navigate('/catalogue')}
            >
              View Catalogue
            </Button>
          </Grid>
        </Root>
      );
    case 'prescription created':
      return (
        <Root>
          <Typography variant="h6" className={classes.capitalizedHeading} gutterBottom>
            {statusObj.formName}
          </Typography>
          <Typography variant="body1">
            Your Candor practitioner has created a new prescription. Click below to view and purchase.
          </Typography>
          <br />
          <Grid className={classes.gridItem}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => navigate('/purchase')}
            >
              View Prescriptions
            </Button>
          </Grid>
        </Root>
      );
    case 'script sent to pharmacy':
      return (
        <Root>
          <Typography variant="h6" gutterBottom>
            Script Sent To Pharmacy
          </Typography>
          <Typography variant="body1">
            We&apos;ve sent your script to the pharmacy you nominated. You may need to contact the pharamcy to organise
            collection.
          </Typography>
        </Root>
      );
    case 'order placed':
      return (
        <Root>
          <Typography variant="h6" gutterBottom>
            Order Placed
          </Typography>
          <Typography variant="body1">
            Your {statusObj.formName} treatment order was placed successfully. We will now organise to have your
            prescription fulfilled by our partner pharmacy.
          </Typography>
        </Root>
      );
    case 'order awaiting shipment':
      return (
        <Root>
          <Typography variant="h6" gutterBottom>
            Order at pharmacy
          </Typography>
          <Typography variant="body1">
            Our partner pharmacy is preparing to ship your order. You&apos;ll receive an email with a tracking link once
            your order has been shipped.
          </Typography>
        </Root>
      );
    case 'order awaiting pickup':
      return (
        <Root>
          <Typography variant="h6" gutterBottom>
            Order at pharmacy
          </Typography>
          <Typography variant="body1">
            Our partner pharmacy is preparing to ship your order. They will contact you shortly to arrange pickup.
          </Typography>
        </Root>
      );
    case 'order sent':
      return (
        <Root>
          <Typography variant="h6" gutterBottom>
            Order Shipped
          </Typography>
          <Typography variant="body1">
            Your order has been shipped by our partner pharmacy. Click below to view your order progress:
            <Button
              variant="text"
              className={classes.textButton}
              endIcon={<OpenInNewIcon />}
              href={statusObj.link}
              target="_blank"
            >
              Australia Post Tracking
            </Button>
          </Typography>
        </Root>
      );
    case 'order collected':
      return (
        <Root>
          <Typography variant="h6" gutterBottom>
            Order Collected
          </Typography>
          <Typography variant="body1">Our partner pharmacy has marked your order as collected.</Typography>
        </Root>
      );
    case 'treatment plan declined':
      return <div />;
    case 'health summary':
      return (
        <Root>
          <Typography variant="h6" gutterBottom>
            Health Summary
          </Typography>
          <Typography variant="body1">
            We need your Health Summary before we can proceed. Once you have your Health Summary click the button below
            to upload
          </Typography>
          <br />
          <Grid className={classes.gridItem}>
            <Button variant="contained" className={classes.button} href={statusObj.link}>
              Documents
            </Button>
          </Grid>
        </Root>
      );
    case 'awaiting agreement acceptance':
      return <AgreementBanner />;
    case 'substitutions announcement':
      return (
        <Root>
          <Typography variant="h6" gutterBottom>
            Important Update
          </Typography>
          <Typography variant="body1">
            <br />
            Please send all future substitution requests to{' '}
            <Link
              underline="none"
              href="mailto:
          substitutions@candor.org"
            >
              substitutions@candor.org
            </Link>
          </Typography>
          <br />
          <Typography variant="body1">
            Please include the requested product in the email and we&apos;ll do our best to respond as quickly as
            possible.
          </Typography>
        </Root>
      );
    default:
      return (
        <Typography variant="body1">
          There was a problem retrieving this data. Please contact{' '}
          <Link underline="none" href="mailto:support@candor.org">
            support@candor.org
          </Link>
        </Typography>
      );
  }
};

export default StatusCardPresentation;
