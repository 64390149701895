import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';
import { allowedUserType, USER_TYPES } from '../../../utils/constants';

const { PHARMACIST } = USER_TYPES;

/**
 * @satisfies {ExtendedRouteObject}
 */
export const pharmacyStockRoutes = {
  name: 'Pharmacy Stock',
  path: 'pharmacy-stock',
  type: 'item',
  userCanAccess: ({ userType }) => allowedUserType([PHARMACIST], userType),
  element: Loadable(React.lazy(() => import('../../../components/PharmacyStockPage/PharmacyStock'))),
};
