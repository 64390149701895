import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';
import { allowedUserType, USER_TYPES } from '../../../utils/constants';

const { PATIENT } = USER_TYPES;

/**
 * @satisfies {ExtendedRouteObject}
 */
export const uploadSupportingDocumentsRoutes = {
  name: 'Upload Supporting Documents',
  path: 'upload-supporting-documents',
  type: 'item',
  userCanAccess: ({ userType }) => allowedUserType([PATIENT], userType),
  element: Loadable(
    React.lazy(() => import('../../../components/patient-documents/documents/upload-supporting-documents')),
  ),
};
