import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';
import { allowedUserType, USER_TYPES } from '../../../utils/constants';
import { baseFormsRoutes } from './baseFormsRoutes';

const { PATIENT } = USER_TYPES;

/**
 * @satisfies {ExtendedRouteObject}
 */
export const consultRoutes = {
  name: 'Consults',
  path: '/consults',
  type: 'layout',
  userCanAccess: ({ userType }) => allowedUserType([PATIENT], userType),
  children: [
    {
      name: 'Consults',
      index: true,
      path: '',
      type: 'item',
      userCanAccess: ({ userType }) => allowedUserType([PATIENT], userType),
      element: Loadable(React.lazy(() => import('../../../components/dashboard/consults/consults-container'))),
    },
    ...baseFormsRoutes,
    {
      name: 'Checkup Form',
      path: 'checkup-form',
      type: 'item',
      element: Loadable(React.lazy(() => import('../../../components/form/other-forms/checkup-form'))),
    },
  ],
};
