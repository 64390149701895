import React from 'react';
import { collection, onSnapshot, query } from 'firebase/firestore';

import { db } from '../firebase-config';

/**
 * @typedef UseCatalogueReturn
 * @property {Record<string, CatalogueItem>} data
 * @property {boolean} loading
 * @property {Error | null} error
 */

/**
 * @param {Object} props
 * @param {boolean} [props.enabled]
 * @returns {UseCatalogueReturn} - The catalogue data.
 */
export const useProductCatalogue = ({ enabled = true } = {}) => {
  const [state, setState] = React.useState({
    data: /** @type {Record<string, CatalogueItem>} */ ({}),
    loading: enabled,
    error: /** @type {Error | null} */ (null),
  });

  React.useEffect(() => {
    if (!enabled) {
      return () => {};
    }

    const unsubscribe = onSnapshot(query(collection(db, 'catalogue')), (querySnapshot) => {
      const data = querySnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = { ...doc.data(), id: doc.id, uid: doc.id };

        return acc;
      }, {});

      setState({ data, loading: false, error: null });
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [enabled]);

  return { data: state.data, loading: state.loading, error: state.error };
};
