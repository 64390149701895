import React from 'react';
import { Outlet } from 'react-router';

import { useAuth } from '../../hooks/useAuth';
import { AuthenticatedUserLayout } from './AuthenticatedUserLayout';

export const SharedBaseLayout = () => {
  const { user } = useAuth();

  // If the user is not authenticated, we don't have a wrapper
  if (!user) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            flexGrow: 1,
            overflow: 'auto',
            minHeight: 0,
          }}
        >
          <Outlet />
        </div>
      </div>
    );
  }

  return <AuthenticatedUserLayout />;
};
