import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { doc, onSnapshot } from 'firebase/firestore';

import { db } from './firebase-config';
import { AuthContext } from './auth-context';
import { USER_TYPES } from './utils/constants';
import { UserDataContext } from './UserDataContext';

const { SUPPLIER, PHARMACIST, DOCTOR } = USER_TYPES;

// TECH DEBT: This is a hack to get the user data from the firestore
// Someone thought it was smart to split user data across multiple collections 🤦🤦🤦
const getUserCollectionName = (userType) => {
  if ([PHARMACIST, SUPPLIER].includes(userType)) {
    return 'orders';
  }

  if (userType === DOCTOR) {
    return 'doctors';
  }

  return 'patients';
};

export const UserDataProvider = ({ children }) => {
  const [userData, setUserData] = useState({});
  const { user, userType } = useContext(AuthContext);

  useEffect(() => {
    if (!user?.uid) {
      return () => {};
    }

    const userCollectionName = getUserCollectionName(userType);
    return onSnapshot(doc(db, userCollectionName, user.uid), (docSnapshot) => {
      const data = docSnapshot.data();
      setUserData(data);
    });
  }, [user?.uid, userType]);

  return <UserDataContext.Provider value={userData}>{children}</UserDataContext.Provider>;
};

UserDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
