import React, { useMemo } from 'react';

import { useAuth } from './hooks/useAuth';
import { useProductCatalogue } from './hooks';
import { Loading } from './components/layout';
import { ProductCatalogueContext } from './ProductCatalogueContext';

export const ProductCatalogueProvider = ({ children }) => {
  const { user } = useAuth();
  const {
    data: catalogue,
    loading: catalogueLoading,
    error: catalogueError,
  } = useProductCatalogue({
    enabled: !!user,
  });

  const value = useMemo(
    () => ({
      catalogue,
      catalogueLoading,
      catalogueError,
    }),
    [catalogue, catalogueLoading, catalogueError],
  );

  if (catalogueLoading) {
    return <Loading debugSection="Product Catalogue" />;
  }

  return <ProductCatalogueContext.Provider value={value}>{children}</ProductCatalogueContext.Provider>;
};
