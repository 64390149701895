import React from 'react';
import { NavLink } from 'react-router';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Typography } from '@mui/material';
import { classes } from '../constants';

/**
 * @typedef {Omit<import('@mui/material').AccordionSummaryProps, 'onClick'> & {
 *   title: string;
 *   to: string;
 *   onClick?: ((event: React.MouseEvent<HTMLAnchorElement>) => void) | undefined;
 * }} TriggerProps
 */

/**
 * @param {TriggerProps} props
 */
export const Trigger = ({ title, to, onClick, style, ...props }) => (
  <NavLink to={to} onClick={onClick} style={{ textDecoration: 'none' }}>
    {({ isActive }) => (
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.accordion}
        style={{ backgroundColor: isActive ? '#F5F5F5' : '', ...style }}
        {...props}
      >
        <Typography className={classes.link}>{title}</Typography>
      </AccordionSummary>
    )}
  </NavLink>
);
