/**
 * FIXME: Refactor and improve. This is a mess.
 */
import PropTypes from 'prop-types';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useCallback, useEffect, useState } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { captureException as sentryCaptureException, captureMessage as sentryCaptureMessage } from '@sentry/react';

import { auth, db } from './firebase-config';
import { UserDataProvider } from './UserDataProvider';
import { getUserRoleFromAuthToken } from './utils/authHelpers';
import { updateUserScopeInSentry } from './utils/sentryHelpers';
import { useAuthAccountStatus } from './hooks/useAuthAccountStatus';
import { AuthContext, DEFAULT_USER_ROLE, DEFAULT_NOTIFICATIONS_COUNT } from './auth-context';
import { ProductCatalogueProvider } from './ProductCatalogueProvider';

/**
 * @typedef {Object} UserInfo
 * @property {import('firebase/auth').User | null} user - The current user
 * @property {string} userType - The type of user
 * @property {boolean} isAuthLoading - Whether the authentication is loading
 */

const DEFAULT_EMAIL_VERIFICATION_DETAILS = {
  oldEmail: '',
  newEmail: '',
  isEmailVerified: false,
};

/**
 * @param {Object} params
 * @param {UserInfo} params.userInfo
 */
const handleUserId = ({ userInfo }) => {
  if (userInfo.userType === 'support' || userInfo.userType === 'nurse' || userInfo.userType === 'doctor') {
    return userInfo.userType;
  }

  return userInfo.user?.uid;
};

// FIXME: Overly complicated and buggy code. Please refactor.
// Seperated this into it's own file instead of leaving it in AuthContext

/**
 * @param {Object} params
 * @param {UserInfo} params.userInfo
 * @param {React.Dispatch<React.SetStateAction<typeof DEFAULT_NOTIFICATIONS_COUNT>>} params.setNotifications
 */
const handleNotification = ({ userInfo, setNotifications }) => {
  try {
    if (!userInfo.user || !userInfo.userType) {
      return () => {};
    }

    const participant = userInfo.userType === 'patient' ? 'participant1' : 'participant2';
    const q = query(
      collection(db, 'conversations'),
      where(`${participant}.id`, '==', handleUserId({ userInfo })),
      where(`${participant}.unreadNotifications`, '==', true),
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const payload = {
        doctor: 0,
        patient: 0,
        nurse: 0,
        pharmacist: 0,
        support: 0,
      };

      const chats = snapshot.docs.map((item) => ({ id: item.id, ...item.data() }));
      // FIXME: This is bug prone, poor quality code. Please refactor.
      chats.forEach((item) => {
        const recipientData =
          handleUserId({ userInfo }) === item.participant1.id ? item.participant2 : item.participant1;
        const senderData = handleUserId({ userInfo }) === item.participant1.id ? item.participant1 : item.participant2;

        if (!recipientData || !senderData) {
          return;
        }

        if (senderData?.email === userInfo.user?.email && userInfo.userType === 'doctor') {
          payload[recipientData.role] += senderData.notifications;
        }

        if (userInfo.userType !== 'doctor') {
          payload[recipientData.role] += senderData.notifications;
        }
      });

      delete payload.undefined;
      setNotifications(payload);
    });

    return () => unsubscribe();
  } catch (error) {
    sentryCaptureMessage('Error obtaining conversations in AuthContext', {
      level: 'debug',
      extra: { userId: userInfo.user?.uid, userType: userInfo.userType },
    });
    sentryCaptureException(error, { extra: { userId: userInfo.user?.uid, userType: userInfo.userType } });

    return () => {};
  }
};
/**
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children - The children to render
 * @returns {JSX.Element}
 */
export const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(
    /** @type {UserInfo} */ ({ user: null, userType: DEFAULT_USER_ROLE, isAuthLoading: true }),
  );

  const [messageOrder, setMessageOrder] = useState(null);
  const [notifications, setNotifications] = useState(DEFAULT_NOTIFICATIONS_COUNT);
  const [conversation, setConversation] = useState(null);
  const [catalogues, setCatalogues] = useState([]);
  const [isAccountPending, setIsAccountPending] = useState(false);
  const [is2faRequired, setIs2faRequired] = useState(false);

  const [emailVerificationDetails, setEmailVerificationDetails] = useState(DEFAULT_EMAIL_VERIFICATION_DETAILS);

  useAuthAccountStatus({
    user: userInfo.user,
    userType: userInfo.userType,
    isAuthLoading: userInfo.isAuthLoading,
    isAccountPending,
    setIsAccountPending,
    is2faRequired,
    setIs2faRequired,
    setEmailVerificationDetails,
  });

  useEffect(() => {
    const unsubscribe = handleNotification({ userInfo, setNotifications });

    return () => {
      unsubscribe();
    };
  }, [userInfo, setNotifications]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        setUserInfo({ user: null, userType: DEFAULT_USER_ROLE, isAuthLoading: false });
        setEmailVerificationDetails(DEFAULT_EMAIL_VERIFICATION_DETAILS);
        updateUserScopeInSentry();

        return; // Exit early if no user is authenticated
      }

      try {
        const tokenResult = await currentUser.getIdTokenResult(true);
        const userRole = getUserRoleFromAuthToken(tokenResult.claims);

        setUserInfo({ user: currentUser, userType: userRole, isAuthLoading: false });

        if (currentUser.emailVerified) {
          setEmailVerificationDetails((previousState) => ({
            ...previousState,
            isEmailVerified: currentUser.emailVerified || previousState.isEmailVerified,
          }));
        }

        updateUserScopeInSentry({ userId: currentUser.uid, userType: userRole });
      } catch (error) {
        sentryCaptureMessage("Error obtaining a User's Firebase Auth Token", {
          level: 'debug',
          extra: { userId: currentUser?.uid },
        });
        sentryCaptureException(error, { extra: { userId: currentUser?.uid } });

        setUserInfo((prevState) => ({ ...prevState, isAuthLoading: false }));
      }
    });

    // Don't forget to cleanup to avoid memory leaks
    return () => {
      unsubscribe();
    };
  }, []);

  const handleLogout = useCallback(async () => {
    signOut(auth).catch((error) => {
      sentryCaptureException(error, {
        extra: { issueIn: 'handleLogout', userType: userInfo.userType, isAccountPending },
      });
    });
  }, [userInfo.userType, isAccountPending]);

  const value = React.useMemo(
    () => ({
      user: userInfo.user,
      userType: userInfo.userType,
      isAuthLoading: userInfo.isAuthLoading,
      messageOrder,
      setMessageOrder,
      conversation,
      setConversation,
      notifications,
      setNotifications,
      emailVerificationDetails,
      setEmailVerificationDetails,
      catalogues,
      setCatalogues,
      isAccountPending,
      is2faRequired,
      handleLogout,
    }),
    [
      userInfo.user,
      userInfo.userType,
      userInfo.isAuthLoading,
      messageOrder,
      setMessageOrder,
      conversation,
      setConversation,
      notifications,
      setNotifications,
      emailVerificationDetails,
      setEmailVerificationDetails,
      catalogues,
      setCatalogues,
      isAccountPending,
      is2faRequired,
      handleLogout,
    ],
  );

  return (
    <AuthContext.Provider value={value}>
      <UserDataProvider>
        <ProductCatalogueProvider>{children}</ProductCatalogueProvider>
      </UserDataProvider>
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
