import { captureException as sentryCaptureException } from '@sentry/react';
import { collection, doc, getDocs, query, where, writeBatch } from '@firebase/firestore';

import { db } from '../firebase-config';

export const DEFAULT_ROLE = 'patient';
export const AUTH_TOKEN_ROLES = ['director', 'doctor', 'nurse', 'support', 'pharmacist', 'supplier'];
const ACTIVITY_MESSAGE = {
  VERIFY_EMAIL: 'verified their email',
  VERIFY_AND_CHANGE_EMAIL: 'verified & changed their email',
  RECOVER_EMAIL: 'recovered their email',
};

/**
 * Extracts the user's role from a custom claim set in our Firebase Auth's token.
 * Searches for a recognised role within the claims and returns the first match.
 * If no role recongised found, it returns the DEFAULT_ROLE ('patient').
 *
 * @param {{ [key: string]: boolean }} authTokenClaims - The authentication token claims.
 * @returns {string} The user role found in the token claims or the DEFAULT_ROLE.
 */
export const getUserRoleFromAuthToken = (authTokenClaims) =>
  AUTH_TOKEN_ROLES.find((role) => authTokenClaims[role]) || DEFAULT_ROLE;

// FIXME: This is a hacky way to do this. We should do a better job of handling this
export const updateEmailInDB = async ({ existingEmail, newEmail, operation, user, userType }) => {
  const batch = writeBatch(db);

  // WARNING: VERY BUGGY CODE AHEAD, LET'S FIX THIS
  try {
    const date = Date.now();
    batch.update(doc(db, 'patients', user.uid), { email: newEmail });
    // Rewrite this
    // await updateConversationParticipant(user?.uid, { email: newEmail })
    const participant = userType === 'patient' ? 'participant1' : 'participant2';

    const q = await getDocs(query(collection(db, 'conversations'), where(`${participant}.id`, '==', user.uid)));
    const conversations = q.docs.map((item) => ({ id: item.id, ...item.data() }));

    conversations.forEach(async (conversation) => {
      batch.update(doc(db, 'conversations', conversation.id), {
        [`${participant}.email`]: newEmail,
      });
    });

    if (userType === 'pharmacist') {
      batch.update(doc(db, 'orders', user.uid), { email: newEmail });
    } else if (userType === 'doctor') {
      batch.update(doc(db, 'doctors', user.uid), { email: newEmail });
    }

    // update backup email
    batch.set(doc(db, 'backups', user.uid), { email: newEmail }, { merge: true });
    // update patient activity
    batch.set(doc(collection(db, 'patients', user.uid, 'activity')), {
      createdAt: date,
      generalData: true,
      author: 'System',
      text: `User ${ACTIVITY_MESSAGE[operation]} from ${existingEmail} to ${newEmail}.`,
    });

    await batch.commit();

    return true;
  } catch (error) {
    sentryCaptureException(error, { extra: { errorCode: error.code, operation } });

    return false;
  }
};
