import React from 'react';
import { Navigate, Outlet } from 'react-router';

import { useAuth } from '../../hooks/useAuth';

const redirectToRequestVerification = () => {
  const currentPath = window.location.pathname;

  if (currentPath === '/') {
    return <Navigate to="/request-verification" />;
  }

  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('redirect', currentPath);

  return <Navigate to={`/request-verification?${searchParams.toString()}`} />;
};

export const VerifiedUserLayout = () => {
  const { emailVerificationDetails, isAccountPending } = useAuth();

  const { isEmailVerified } = emailVerificationDetails;

  if (!isEmailVerified) {
    return redirectToRequestVerification();
  }

  if (isEmailVerified && isAccountPending) {
    // If the user is verified and their account is pending, redirect them to the pending account page
    return <Navigate to="/pending-account" />;
  }

  return <Outlet />;
};
