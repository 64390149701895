import React from 'react';
import { Navigate, redirect } from 'react-router';

import { auth } from '../../firebase-config';
import { Loading } from '../../components/layout';
import { Loadable } from '../../components/navigation/Loadable';
import {
  UnauthenticatedUserLayout,
  AuthenticatedUserLayout,
  UnverifiedUserLayout,
  SharedLayout,
  VerifiedUserLayout,
} from '../layouts';
import { ErrorHandler } from '../ErrorHandler';
import { getFormNavigationPath } from '../getFormNavigationPath';
import { sharedRoutes } from './sharedRoutes';
import { pendingAccountRoute } from './base-routes';
import { verifiedUserRoutes } from './verifiedUserRoutes';
import { unverifiedUserRoutes } from './unverifiedUserRoutes';
import { unauthenticatedUserRoutes } from './unauthenticatedUserRoutes';

/**
 * @param {Object} params
 * @param {Request} params.request - The request object.
 * @returns {null}
 */
const handleRedirect = ({ request }) => {
  const url = new URL(request.url);
  const redirectTo = url.searchParams.get('redirect') ?? '';

  if (!redirectTo) {
    return null;
  }

  // Add all other search params to the redirect url
  const searchParams = new URLSearchParams(url.searchParams);
  searchParams.delete('redirect');

  if (searchParams.size > 0) {
    throw redirect(`${redirectTo}?${searchParams.toString()}`);
  }

  throw redirect(redirectTo);
};

/**
 * @type {import('react-router').LoaderFunction}
 */
const unauthenticatedUserLoader = async ({ request }) => {
  // If the user is authenticated, they can access the shared routes
  if (!auth.currentUser) {
    return null;
  }

  return handleRedirect({ request });
};

/**
 * @type {import('react-router').LoaderFunction}
 */
const sharedRoutesLoader = async ({ request }) => {
  // If the user is NOT authenticated or is NOT verified, they can access the ALL shared routes
  if (!auth.currentUser || !auth.currentUser.emailVerified) {
    return null;
  }

  const url = new URL(request.url);

  // If the user is authenticated and verified, they can access the shared routes
  //  BUT NOT the forms -> We want to redirect them to authenticated form view
  const formPath = getFormNavigationPath({ pathname: url.pathname });

  if (formPath) {
    throw redirect(formPath);
  }

  return handleRedirect({ request });
};

/**
 * @satisfies {ExtendedRouteObject[]}
 */
export const routes = [
  {
    name: 'Shared Routes Layout',
    type: 'layout',
    loader: sharedRoutesLoader,
    element: <SharedLayout />,
    hydrateFallbackElement: <Loading debugSection="Shared Layout" />,
    errorElement: <ErrorHandler type="shared" />,
    children: sharedRoutes,
  },
  {
    name: 'Authenticated User Layout',
    type: 'layout',
    element: <AuthenticatedUserLayout />,
    hydrateFallbackElement: <Loading debugSection="Authenticated Layout" />,
    errorElement: <ErrorHandler type="authenticated" />,
    children: [
      {
        name: 'Verified User Layout',
        type: 'layout',
        element: <VerifiedUserLayout />,
        hydrateFallbackElement: <Loading debugSection="Verified User Layout" />,
        errorElement: <ErrorHandler type="verified" />,
        children: verifiedUserRoutes,
      },
      {
        name: 'Payment Success',
        path: 'payment-success',
        type: 'item',
        element: Loadable(React.lazy(() => import('../../components/landing-pages/payment-success'))),
      },
      {
        name: 'Consult Payment Success',
        path: 'consult-payment-success',
        type: 'item',
        element: Loadable(React.lazy(() => import('../../components/landing-pages/consult-payment-success'))),
      },
    ],
  },
  // This is outside of the authenitcated user routes as we do not want any layout
  {
    name: 'Unverified User Layout',
    type: 'layout',
    element: <UnverifiedUserLayout />,
    hydrateFallbackElement: <Loading debugSection="Unverified User Layout" />,
    errorElement: <ErrorHandler type="unverified" />,
    children: unverifiedUserRoutes,
  },
  // This is outside of the authenitcated user routes as we do not want any layout
  pendingAccountRoute,
  {
    name: 'Unauthenticated User Layout',
    type: 'layout',
    loader: unauthenticatedUserLoader,
    element: <UnauthenticatedUserLayout />,
    hydrateFallbackElement: <Loading debugSection="Unauthenticated User Layout" />,
    errorElement: <ErrorHandler type="unauthenticated" />,
    children: unauthenticatedUserRoutes,
  },
  {
    name: '404/Redirect',
    path: '*',
    type: 'item',
    element: <Navigate replace to="/?foo=bar" />,
  },
];
