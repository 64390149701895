import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';
import { USER_TYPES, allowedUserType } from '../../../utils/constants';

const { NURSE, DIRECTOR, SUPPORT } = USER_TYPES;

/**
 * @satisfies {ExtendedRouteObject}
 */
export const adminRoutes = {
  name: 'Admin Control',
  path: 'admin-control',
  type: 'item',
  element: Loadable(React.lazy(() => import('../../../components/admin/AdminContainer'))),
  userCanAccess: ({ userType }) => allowedUserType([NURSE, DIRECTOR, SUPPORT], userType),
};
