import React from 'react';
import { Navigate, useLocation } from 'react-router';

import { getFormNavigationPath } from '../getFormNavigationPath';

export const BaseNavigateWithRedirect = () => {
  const { pathname } = useLocation();

  const formPath = getFormNavigationPath({ pathname });

  return <Navigate replace to={formPath ?? '/dashboard'} />;
};
