import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';
import { allowedUserType, USER_TYPES } from '../../../utils/constants';

const { SUPPORT, DIRECTOR, PHARMACIST } = USER_TYPES;

const PharmacyOrdersPage = React.lazy(() => import('../../../features/pharmacyDirectory/pages/PharmacyOrdersPage'));

/**
 * TODO: This can be refactored... we should have better routing for this, or
 * use query params to filter the orders.
 */

/**
 * @satisfies {ExtendedRouteObject}
 */
export const ordersRoutes = {
  name: 'Orders Layout',
  type: 'layout',
  userCanAccess: ({ userType }) => allowedUserType([SUPPORT, DIRECTOR, PHARMACIST], userType),
  element: Loadable(React.lazy(() => import('../../../features/pharmacyDirectory/pages/PharmacyLayout'))),
  children: [
    {
      name: 'Orders',
      path: 'orders',
      type: 'item',
      userCanAccess: ({ userType }) => allowedUserType([SUPPORT, DIRECTOR, PHARMACIST], userType),
      element: <PharmacyOrdersPage key="current" tab="current" />,
    },
    {
      name: 'Order History',
      path: 'order-history',
      type: 'item',
      userCanAccess: ({ userType }) => allowedUserType([PHARMACIST], userType),
      element: <PharmacyOrdersPage key="historic" tab="historic" />,
    },
  ],
};
