import { styled } from '@mui/material/styles';
import React, { useEffect, useRef } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { Badge, Drawer, ListItemText, MenuItem } from '@mui/material';

import {
  adminRoutes,
  appointmentsRoutes,
  assignedPatientsRoutes,
  consultRoutes,
  messagesRoutes,
  ordersRoutes,
  patientsRoutes,
  pharmaciesRoutes,
  pharmacyStockRoutes,
  purchaseHistoryRoutes,
  purchaseRoutes,
  tgaRoutes,
} from '../../../Router/routes/base-routes';
import { db } from '../../../firebase-config';
import { useAuthContext } from '../../../features/pharmacyDirectory/hooks/useAuthContext';
import { FORMS, getValidForms, USER_TYPES, allowedUserType } from '../../../utils/constants';
import { classes } from './constants';
import { NavigationLink } from './NavigationLink';
import { CatalogueMenuItems } from './CatalogueMenuItems';
import { AccountInfoMenuItems } from './AccountInfoMenuItems';
import { PatientMedicalInfoMenuItems } from './PatientMedicalInfoMenuItems';

const { DOCTOR, NURSE, PHARMACIST, DIRECTOR, SUPPORT, SUPPLIER } = USER_TYPES;

const StyledDrawer = styled(Drawer)(() => ({
  [`& .${classes.link}`]: {
    textDecoration: 'none',
    color: '#0E6D81',
    width: 'auto',
    padding: '15px 32px 15px 32px',
  },

  [`& .${classes.drawer}`]: {
    '@media (max-width: 1000px)': {
      width: 220,
    },
    width: 220,
  },

  [`& .${classes.browser}`]: {
    '@media (max-width: 1000px)': {
      display: 'none',
    },
    textDecoration: 'none',
    color: '#0E6D81',
    width: 'auto',
  },

  [`& .${classes.native}`]: {
    '@media (min-width: 1001px)': {
      display: 'none',
    },
  },

  [`& .${classes.selected}`]: {
    textDecoration: 'none',
    color: '#0E6D81',
    width: 'auto',
    backgroundColor: '#f5f5f5',
    padding: '15px 32px 15px 32px',
  },

  [`& .${classes.accordion}`]: {
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    padding: '0px 20px 0px 0px',
    margin: 0,
    height: 54,
  },
}));

export const Sidebar = ({ open, onClose, handleLogoutClose, from }) => {
  const isMounted = useRef(false);

  const { user, userType, notifications, setCatalogues } = useAuthContext();
  const messagesNotifCount = Object.values(notifications ?? {}).reduce((acc, val) => acc + val, 0);

  const getAllowedUserTypes = React.useCallback(
    (allowedUserTypesArray) => allowedUserType(allowedUserTypesArray, userType),
    [userType],
  );

  useEffect(() => {
    isMounted.current = true;
    async function getData() {
      const docSnap = getAllowedUserTypes([SUPPLIER, PHARMACIST])
        ? (await getDoc(doc(db, 'orders', user.uid))).data()
        : (await getDoc(doc(db, 'patients', user.uid))).data();

      // get the treatments for the user
      // fixed the old code ternary error
      let tempTreatments = [];
      if (getAllowedUserTypes([PHARMACIST])) {
        tempTreatments = docSnap.ordersAccepted;
      } else if (getAllowedUserTypes([SUPPLIER])) {
        tempTreatments = getValidForms(docSnap.ordersAccepted);
      } else if (getAllowedUserTypes([DOCTOR, NURSE, DIRECTOR, SUPPORT])) {
        tempTreatments = [...FORMS];
      } else {
        // this is for patient userType
        tempTreatments = docSnap.catalogueItems
          ? Object.keys(docSnap.catalogueItems).filter((item) => docSnap.catalogueItems[item].length > 0)
          : [];
      }

      if (isMounted.current && tempTreatments?.length > 0) {
        setCatalogues(tempTreatments.sort());
      }
    }
    if (isMounted.current && user) {
      getData();
    }
    return () => {
      isMounted.current = false;
    };
  }, [user, setCatalogues, getAllowedUserTypes]);

  return (
    <StyledDrawer
      open={from === 'nav' ? open : true}
      anchor="left"
      variant={from === 'nav' ? 'temporary' : 'permanent'}
      onClose={onClose}
    >
      <div className={classes.drawer}>
        <NavigationLink to="/dashboard" onClose={onClose}>
          <ListItemText primary="Dashboard" />
        </NavigationLink>

        <NavigationLink
          to={consultRoutes.path}
          onClose={onClose}
          userCanAccess={consultRoutes.userCanAccess({ userType })}
        >
          <ListItemText primary={consultRoutes.name} />
        </NavigationLink>

        <NavigationLink
          to={purchaseRoutes.path}
          onClose={onClose}
          userCanAccess={purchaseRoutes.userCanAccess({ userType })}
        >
          <ListItemText primary={purchaseRoutes.name} />
        </NavigationLink>

        <NavigationLink
          to={patientsRoutes.path}
          onClose={onClose}
          userCanAccess={patientsRoutes.userCanAccess({ userType })}
        >
          <ListItemText primary={patientsRoutes.name} />
        </NavigationLink>

        <NavigationLink
          to={assignedPatientsRoutes.path}
          onClose={onClose}
          userCanAccess={assignedPatientsRoutes.userCanAccess({ userType })}
        >
          <ListItemText primary="Assigned" />
        </NavigationLink>

        <NavigationLink to="/orders" onClose={onClose} userCanAccess={ordersRoutes.userCanAccess({ userType })}>
          <ListItemText primary="Orders" />
        </NavigationLink>

        <NavigationLink
          to={pharmacyStockRoutes.path}
          onClose={onClose}
          userCanAccess={pharmacyStockRoutes.userCanAccess({ userType })}
        >
          <ListItemText primary="Stock" />
        </NavigationLink>

        <CatalogueMenuItems onClose={onClose} />

        <NavigationLink
          to={messagesRoutes.path}
          overrideTo={getAllowedUserTypes([SUPPORT, PHARMACIST]) ? '/messages/list/patients' : undefined}
          onClose={onClose}
          userCanAccess={messagesRoutes.userCanAccess({ userType })}
        >
          <ListItemText primary="Messaging" />
          <Badge badgeContent={messagesNotifCount} color="secondary" />
        </NavigationLink>

        <NavigationLink
          to={pharmaciesRoutes.path}
          onClose={onClose}
          userCanAccess={pharmaciesRoutes.userCanAccess({ userType })}
        >
          <ListItemText primary={pharmaciesRoutes.name} />
        </NavigationLink>

        <NavigationLink to="/order-history" onClose={onClose} userCanAccess={getAllowedUserTypes([PHARMACIST])}>
          <ListItemText primary="Order History" />
        </NavigationLink>

        <NavigationLink
          to={appointmentsRoutes.path}
          onClose={onClose}
          userCanAccess={appointmentsRoutes.userCanAccess({ userType })}
        >
          <ListItemText primary={appointmentsRoutes.name} />
        </NavigationLink>

        <NavigationLink
          to={purchaseHistoryRoutes.path}
          onClose={onClose}
          userCanAccess={purchaseHistoryRoutes.userCanAccess({ userType })}
        >
          <ListItemText primary={purchaseHistoryRoutes.name} />
        </NavigationLink>

        <NavigationLink to={tgaRoutes.path} onClose={onClose} userCanAccess={tgaRoutes.userCanAccess({ userType })}>
          <ListItemText primary={tgaRoutes.name} />
        </NavigationLink>

        <NavigationLink to={adminRoutes.path} onClose={onClose} userCanAccess={adminRoutes.userCanAccess({ userType })}>
          <ListItemText primary="Admin" />
        </NavigationLink>

        <PatientMedicalInfoMenuItems onClose={onClose} />

        <AccountInfoMenuItems onClose={onClose} />

        {user !== null && (
          <MenuItem onClick={handleLogoutClose} className={classes.link}>
            <ListItemText primary="Logout" />
          </MenuItem>
        )}
      </div>
    </StyledDrawer>
  );
};
