import React from 'react';
import { useRouteError } from 'react-router';
import { captureException as sentryCaptureException } from '@sentry/react';

import { ErrorFallback } from '../components/layout/error-fallback';

/**
 * Handles the "TypeError: Importing a module script failed." issue.
 * Refreshes the page to fix the issue.
 * @param {Object} param0
 */
const handleOutOfDateModuleError = ({ error }) => {
  if (!error.message.includes('Importing a module script failed.')) {
    return;
  }

  const searchParams = new URLSearchParams(window.location.search);
  const refreshAttempts = Number(searchParams.get('refreshAttempts') ?? 0);

  if (refreshAttempts >= 1) {
    return;
  }

  const url = new URL(window.location.href);
  url.searchParams.set('refreshAttempts', (refreshAttempts + 1).toString());

  window.location.href = url.toString();
};

/**
 *
 * @param {Object} props
 * @param {"verified" | "unverified" | "unauthenticated" | "authenticated" | "shared"} props.type - The route that the error occurred on.
 * @returns
 */
export const ErrorHandler = ({ type }) => {
  const error = useRouteError();

  React.useEffect(() => {
    handleOutOfDateModuleError({ error });

    sentryCaptureException(error, { tags: { type } });
  }, [error, type]);

  return (
    <ErrorFallback
      resetError={() => {
        window.location.href = '/';
      }}
    />
  );
};
