/**
 * FIXME: This is mostly copied from the old useAccountStatus hook.
 * We should refactor this to be more maintainable and easier to understand.
 */

import { useEffect } from 'react';
import { redirect } from 'react-router';
import { doc, getDoc } from 'firebase/firestore';
import { captureException as sentryCaptureException } from '@sentry/react';

import { db } from '../firebase-config';
import { updateEmailInDB } from '../utils/authHelpers';

// TODO: FUTURE: This is temp until we enforce this for staging as well.
const PROD_PROJECT_ID = 'candor-react';

// FUTURE: Migrate our db away from using these 3 fields, move to a single field for
// userType/accountType/userRole and not different values and status e.g "PENDING", "APPROVED", "SUSPENDED"
const USER_ACCOUNTS_NEEDING_APPROVAL = ['isSupplier', 'isDoctor', 'isPharmacy'];
const USER_TYPES_WITH_MANDATORY_MFA = ['doctor', 'support', 'nurse', 'director'];
/**
 * Checks if 2FA/MFA is mandatory for the given user type and current user status.
 * @param {Object} params - The parameters object.
 * @param {string} params.userType - The type of the user.
 * @param {Object} params.currentAuthUser - The current user object from Firebase Auth.
 * @param {string} params.projectId - The Firebase project ID from environment variables.
 * @returns {boolean} True if 2FA is mandatory, false otherwise.
 */
export const is2faMandatory = ({ userType, currentAuthUser, projectId }) =>
  !!userType &&
  USER_TYPES_WITH_MANDATORY_MFA.includes(userType) &&
  !currentAuthUser?.reloadUserInfo?.mfaInfo &&
  projectId === PROD_PROJECT_ID;

/**
 * Checks if the user's account is currently pending approval.
 * This data should come from the 'patients' collection.
 * Only specific users can be in a pending approval state like Doctors, Pharamacists, or Suppliers
 * TODO: QUESTION: Currently unsure why we're checking for offline??? Someone please explain/update this comment.
 * FUTURE: FIX-ME: type "offline" should be moved to constants once it's clear what it's purpose is.
 * @param {Object} params - The parameters object.
 * @param {Object} params.userData - The user data in firestore to check.
 * @param {string[]} [params.fieldNamesToCheck] - List of the fieldNames to check for user account roles that require approval.
 * @returns {boolean} True if the user account is pending approval, false otherwise.
 */
export const isAccountPendingApproval = ({ userData = {}, fieldNamesToCheck = USER_ACCOUNTS_NEEDING_APPROVAL }) =>
  fieldNamesToCheck.some((role) => !!userData?.[role]) && userData?.type === 'offline';

/**
 * @param {Object} props
 * @param {import('firebase/auth').User | null} props.user
 * @param {string} props.userType
 * @param {boolean} props.isAuthLoading
 * @param {boolean} props.isAccountPending
 * @param {boolean} props.is2faRequired
 * @param {React.Dispatch<React.SetStateAction<{isEmailVerified: boolean, oldEmail: string, newEmail: string}>>} props.setEmailVerificationDetails
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setIsAccountPending
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setIs2faRequired
 */
export const useAuthAccountStatus = ({
  user,
  userType,
  isAuthLoading,
  isAccountPending,
  is2faRequired,
  setEmailVerificationDetails,
  setIsAccountPending,
  setIs2faRequired,
}) => {
  // TODO: FIXME: There are bugs here, look at the effects dependencies, this needs to be cleaned up
  useEffect(() => {
    // FIXME: This is hacky, clean this up to be more maintainable. Maybe start from scratch.
    const fetchAndEvaluateUserStatus = async () => {
      if (!user) {
        return;
      }
      try {
        const userDocRef = doc(db, 'patients', user?.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();

        if (!userData) {
          return;
        }

        // This is the solution for unauthenticated email recovery and unauthenticated email change since they can't update the userDoc email when they are not logged in
        // NOTE: this will also sync the auth user email with the firestore user patient email
        if (user?.email !== userData.email) {
          const operation = isAccountPending ? 'VERIFY_AND_CHANGE_EMAIL' : 'RECOVER_EMAIL';
          const result = await updateEmailInDB({
            existingEmail: userData.email,
            newEmail: user?.email,
            operation,
            user,
            userType,
          });

          if (result) {
            setEmailVerificationDetails((previousState) => ({
              ...previousState,
              isEmailVerified: true,
            }));
          }

          if (operation === 'RECOVER_EMAIL') {
            redirect('/dashboard');

            return;
          }

          redirect(`/auth-landing-page-success?operation=${operation}`);

          return;
        }

        setIsAccountPending(isAccountPendingApproval({ userData }));
        setIs2faRequired(
          is2faMandatory({
            userType,
            currentAuthUser: user,
            projectId: import.meta.env.VITE_APP_FIREBASE_CONFIG_PROJECT_ID,
          }),
        );
      } catch (error) {
        sentryCaptureException(error, {
          extra: { is2faRequired, isAuthLoading, isAccountPending },
        });
      }
    };

    fetchAndEvaluateUserStatus();
  }, [
    user,
    userType,
    is2faRequired,
    isAccountPending,
    isAuthLoading,
    setEmailVerificationDetails,
    setIsAccountPending,
    setIs2faRequired,
  ]);
};
