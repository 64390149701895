import React, { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router';

import './index.css';
import App from './App';
import Loading from './components/layout/loading';

Sentry.init({
  dsn: 'https://19127fa0231bcccc0c6ce736f0e6d12e@o4506737640144896.ingest.sentry.io/4506744414404608',
  environment: import.meta.env.MODE || 'development',
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  integrations: [
    Sentry.reactRouterV7BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      maskAllInputs: true,
      blockAllMedia: false,
    }),
    Sentry.extraErrorDataIntegration({ depth: 7 }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.01, //  Capture 1% of transactions
  // Session Replay
  replaysSessionSampleRate: 0.01, // Capture 1% of sessions
  replaysOnErrorSampleRate: 1.0, // Capture 100% of errors (Note: we may reduce this if cost is too high)
});

const container = document.getElementById('root');
// TODO: Handle container being null in the future, render a page not found or enable JS? :thinking:
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </React.StrictMode>,
);
