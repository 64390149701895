import React from 'react';

import { Loading } from '../layout';

/**
 * @param {React.LazyExoticComponent<() => JSX.Element | null>} Component
 * @returns {React.ReactNode}
 */
export const Loadable = (Component) => (
  <React.Suspense fallback={<Loading debugSection={Component?.name || 'Loadable'} />}>
    <Component />
  </React.Suspense>
);
