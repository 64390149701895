import React from 'react';
import { Navigate, Outlet } from 'react-router';
import { Box, CssBaseline } from '@mui/material';

import { useAuth } from '../../hooks/useAuth';
import Navbar from '../../components/navigation/navbar';
import { Sidebar } from '../../components/navigation/Sidebar';

const DRAWER_WIDTH = 220;

export const AuthenticatedUserLayout = () => {
  const { user, handleLogout } = useAuth();

  // If the user is not authenticated, redirect them to the login page
  if (!user) {
    const currentPath = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set('redirect', currentPath);

    return <Navigate to={`/login?${searchParams.toString()}`} />;
  }

  return (
    <Box
      id="mainRoot"
      sx={{ display: 'flex', height: '100dvh', overscrollBehavior: 'none', minHeight: '100dvh', width: '100%' }}
    >
      <CssBaseline />

      <Box
        component="nav"
        sx={{
          width: { sm: DRAWER_WIDTH },
          flexShrink: { sm: 0 },
          '@media (max-width: 1000px)': {
            display: 'none',
          },
        }}
      >
        <Sidebar handleLogoutClose={handleLogout} open={undefined} onClose={undefined} from={undefined} />
      </Box>

      <Box
        component="main"
        sx={{
          width: {
            xs: '100%',
            sm: `calc(100% - ${DRAWER_WIDTH}px)`,
            '@media (max-width: 1000px)': {
              width: '100%',
            },
          },
          minHeight: '100dvh',
          height: '100dvh',
          display: 'flex',
          flexDirection: 'column',
          overscrollBehavior: 'none',
          pt: 2,
          gap: 2,
        }}
      >
        <Navbar />
        <div
          style={{
            flex: 1,
            minWidth: '100%',
            overflow: 'auto',
            paddingBottom: '2rem',
          }}
        >
          <Outlet />
        </div>
      </Box>
    </Box>
  );
};
