/**
 * Handles the form navigation path.
 * Transforms `/{form-name}-form` to `/consults/{form-name}-form`.
 * @param {Object} params
 * @param {string} params.pathname
 * @returns {string | null}
 */
export const getFormNavigationPath = ({ pathname }) => {
  const formNameSplit = pathname.split('/')[1].split('-form');
  const formName = formNameSplit[0];

  if (formName && formNameSplit.length === 2) {
    return `/consults/${formName}-form`;
  }

  return null;
};
