import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { Grid2 as Grid, Paper, Modal, Box } from '@mui/material';
import { deleteField, doc, writeBatch, addDoc, collection } from 'firebase/firestore';
import { captureException as sentryCaptureException } from '@sentry/react';

import { db } from '../../../firebase-config';
import { AuthContext } from '../../../auth-context';
import StatusCardPresentation from './status-card-presentation';
import PaymentContainer from '../../checkout/PaymentContainer';
import { bookingPriceTranslator } from '../../../utils/form-translators';

const PREFIX = 'StatusCard';

const classes = {
  section: `${PREFIX}-section`,
  formHeading: `${PREFIX}-formHeading`,
  paper: `${PREFIX}-paper`,
  paperHolder: `${PREFIX}-paperHolder`,
  cancelButtonHolder: `${PREFIX}-cancelButtonHolder`,
  cancelButton: `${PREFIX}-cancelButton`,
  button: `${PREFIX}-button`,
  gridItem: `${PREFIX}-gridItem`,
  divider: `${PREFIX}-divider`,
  link: `${PREFIX}-link`,
  textButton: `${PREFIX}-textButton`,
  capitalizedHeading: `${PREFIX}-capitalizedHeading`,
  modalGrid: `${PREFIX}-modalGrid`,
};

const StyledGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // height: '100%',
  width: '100%',
  marginBottom: 50,

  [`&.${classes.section}`]: {},

  [`& .${classes.formHeading}`]: {
    textTransform: 'capitalize',
  },

  [`& .${classes.paper}`]: {
    position: 'relative',
    padding: '20px',
    margin: '20px 20px 0px 20px',
    width: '100%',
  },

  [`& .${classes.paperHolder}`]: {
    display: 'flex',
    alignItems: 'stretch',
    width: '100%',
  },

  [`& .${classes.cancelButtonHolder}`]: {
    position: 'relative',
  },

  [`& .${classes.cancelButton}`]: {
    height: 50,
    position: 'absolute',
    right: 0,
    top: 0,
  },

  [`& .${classes.button}`]: {
    position: 'absolute',
    bottom: 20,
  },

  [`& .${classes.gridItem}`]: {
    height: 30,
  },

  [`& .${classes.divider}`]: {
    marginBottom: 10,
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
  },

  [`& .${classes.textButton}`]: {
    padding: '10px 0px 0px 0px',
  },

  [`& .${classes.capitalizedHeading}`]: {
    textTransform: 'capitalize',
  },
}));

const StyledModalContainer = styled(Modal)(() => ({
  margin: '10px',
  [`& .${classes.modalGrid}`]: {
    margin: '10% auto',
    '@media (max-width: 900px)': {
      margin: '10px auto',
      maxWidth: '600px',
      overflow: 'auto',
    },
    maxWidth: '1200px',
    borderRadius: '5px',
    maxHeight: '90vh',
  },
}));

const StatusCard = (props) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { statusObj, pendingTreatments, setPendingTreatments, setThumbnailsToDisplay } = props;
  const [stripePrice, setStripePrice] = useState('');
  const [formRef, setFormRef] = useState('');
  const [termsRead, setTermsRead] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  if (statusObj.title === 'awaiting booking payment') {
    // Add price codes to array in case they're needed
    statusObj.stripePrice = bookingPriceTranslator[statusObj.formName];
  }

  const consultPaymentButton = (price, form) => {
    setModalOpen(true);
    setStripePrice(price);
    setFormRef(form);
  };

  const startOverdueCheckup = async () => {
    await addDoc(collection(db, 'tasks'), {
      due: Date.now() + 172800000,
      options: { userId: user?.uid, component: '', iteration: 1, type: 'restart' },
      worker: 'checkup_handler',
    });
    navigate('/checkup-form', { replace: true });
  };

  const clearForm = async (formName) => {
    const newPendingTreatments = pendingTreatments.filter((oldTreatment) => oldTreatment.formName !== formName);
    setPendingTreatments(newPendingTreatments);
    setThumbnailsToDisplay((tl) => [...tl, formName]);
    try {
      const batch = writeBatch(db);
      batch.update(doc(db, 'patients', user?.uid), {
        [`forms.${formName}`]: deleteField(),
        banner: deleteField(),
      });
      batch.update(doc(db, 'patients', user?.uid, 'general', 'information'), {
        [`forms.${formName}`]: deleteField(),
      });
      await batch.commit();
    } catch (error) {
      sentryCaptureException(error, {
        extra: { formName, newPendingTreatments, issueIn: 'handleRemoveForm' },
      });
    }
  };

  const payPriceDifference = () => {
    setModalOpen(true);
  };

  return (
    <StyledGrid data-testid="Patient-Notications-Page" className={classes.section}>
      <Paper className={classes.paper}>
        <StatusCardPresentation
          statusObj={statusObj}
          pendingTreatments={pendingTreatments}
          consultPaymentButton={consultPaymentButton}
          clearForm={clearForm}
          startOverdueCheckup={startOverdueCheckup}
          payPriceDifference={payPriceDifference}
        />
      </Paper>

      <StyledModalContainer open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box className={classes.modalGrid}>
          {statusObj.title === 'awaiting booking payment' && (
            <PaymentContainer
              mode="booking fee"
              type="booking fee"
              description="Rebooking Fee"
              selectedTreatments={[stripePrice]}
              formName={formRef}
            />
          )}
          {statusObj.title === 'awaiting substitution fee' && <PaymentContainer reference={statusObj.reference} />}
          {statusObj.title !== 'awaiting substitution fee' && statusObj.title !== 'awaiting booking payment' && (
            <PaymentContainer
              termsRead={termsRead}
              setTermsRead={setTermsRead}
              mode="consult"
              type="consult"
              description="Consultation Fee"
              selectedTreatments={[stripePrice]}
              formName={formRef}
            />
          )}
        </Box>
      </StyledModalContainer>
    </StyledGrid>
  );
};

export default StatusCard;
