import React from 'react';
import { styled } from '@mui/material/styles';
import { Paper, Container, Typography, Button } from '@mui/material';

const PREFIX = 'ErrorFallback';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
};

const StyledContainer = styled(Container)({
  [`&.${classes.gridContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '70vh',
  },
  [`& .${classes.paper}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    width: '90%',
    maxWidth: '400px',
  },
});

/**
 * Renders a custom fallback UI when our ErrorBoundary catches an error.
 *
 * @param {Object} props - The props for the component.
 * @param {() => void} props.resetError - Function to reset the error boundary.
 */
export const ErrorFallback = ({ resetError }) => (
  <StyledContainer maxWidth="sm" className={classes.gridContainer}>
    <Paper className={classes.paper}>
      <Typography variant="h5" align="center" gutterBottom>
        An Error Occured
      </Typography>
      <br />
      <Typography variant="body1" align="center">
        The error has been reported to Candor Support.
      </Typography>
      <br />
      <Button variant="contained" color="primary" onClick={resetError}>
        Home
      </Button>
    </Paper>
  </StyledContainer>
);
