const PREFIX = 'Sidebar';

export const classes = {
  link: `${PREFIX}-link`,
  drawer: `${PREFIX}-drawer`,
  browser: `${PREFIX}-browser`,
  native: `${PREFIX}-native`,
  selected: `${PREFIX}-selected`,
  accordion: `${PREFIX}-accordion`,
};
