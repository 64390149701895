import React from 'react';
import { MenuItem } from '@mui/material';
import { NavLink, useNavigate } from 'react-router';

import { classes } from './constants';

/**
 * @param {object} props
 * @param {string} props.to - The URL to navigate to
 * @param {string | undefined} [props.overrideTo] - The URL to override the navigation to. This is a bit of a hack to still get the active state for nested routes.
 * @param {() => void} props.onClose - The function to call when the link is clicked
 * @param {boolean} [props.userCanAccess] - Whether the user can access the link
 * @param {React.ReactNode} props.children - The children to render inside the link
 * @returns {React.ReactNode} The NavigationLink component
 */
export const NavigationLink = ({ to, overrideTo, onClose, userCanAccess = true, children }) => {
  const navigate = useNavigate();

  /**
   * This is used to prevent the default navigation behavior and instead
   * navigate to the overrideTo URL. If we pass the value of `overrideTo` into
   * the `to` prop, we don't get the active state for nested routes.
   * @param {React.MouseEvent<HTMLAnchorElement>} event
   */
  const handleOnClick = (event) => {
    if (!overrideTo) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    navigate(overrideTo);
  };

  if (!userCanAccess) {
    return null;
  }

  return (
    <NavLink to={to} style={{ textDecoration: 'none', color: '#0E6D81' }} onClick={handleOnClick}>
      {({ isActive }) => (
        <MenuItem onClick={onClose} className={isActive ? classes.selected : classes.link}>
          {children}
        </MenuItem>
      )}
    </NavLink>
  );
};
