import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';
import { allowedUserType, USER_TYPES } from '../../../utils/constants';

const { PATIENT } = USER_TYPES;

/**
 * @satisfies {ExtendedRouteObject}
 */
export const medicalInfoRoutes = {
  name: 'Medical Info',
  path: 'medical-info',
  type: 'parent',
  userCanAccess: ({ userType }) => allowedUserType([PATIENT], userType),
  children: [
    {
      index: true,
      type: 'item',
      path: '',
      element: Loadable(
        React.lazy(() =>
          import('../../../components/medical-info/MedicalInfoIndexPage').then((module) => ({
            default: module.MedicalInfoIndexPage,
          })),
        ),
      ),
    },
    {
      name: 'Medical Info Page',
      path: ':medicalInfoPage',
      type: 'item',
      element: Loadable(React.lazy(() => import('../../../components/medical-info/medical-info-container'))),
    },
  ],
};
