import React from 'react';
import { Navigate, Outlet } from 'react-router';

import { useAuth } from '../../hooks/useAuth';

export const UnauthenticatedUserLayout = () => {
  const { user } = useAuth();

  if (!user) {
    return <Outlet />;
  }

  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');

  if (!redirect) {
    return <Navigate to="/" />;
  }

  searchParams.delete('redirect');

  return <Navigate to={`${redirect}?${searchParams.toString()}`} />;
};
