import React from 'react';

export const DEFAULT_USER_ROLE = '';
export const DEFAULT_NOTIFICATIONS_COUNT = {
  doctor: 0,
  patient: 0,
  nurse: 0,
  pharmacist: 0,
  support: 0,
};

/**
 * @typedef {Object} EmailVerificationDetails
 * @property {string} oldEmail - The old email
 * @property {string} newEmail - The new email
 * @property {boolean} isEmailVerified - Whether the email is verified
 */

/**
 * @typedef {Object} AuthContextValue
 * @property {import('firebase/auth').User | null} user - The current user
 * @property {string} userType - The type of user
 * @property {boolean} isAuthLoading - Whether the authentication is loading
 * @property {Object} messageOrder - The message order
 * @property {Function} setMessageOrder - The function to set the message order
 * @property {Object} conversation - The conversation
 * @property {Function} setConversation - The function to set the conversation
 * @property {Object} notifications - The notifications
 * @property {Function} setNotifications - The function to set the notifications
 * @property {EmailVerificationDetails} emailVerificationDetails - The email verification details
 * @property {React.Dispatch<React.SetStateAction<EmailVerificationDetails>>} setEmailVerificationDetails - The function to set the email verification details
 * @property {Object} catalogues - The catalogues
 * @property {Function} setCatalogues - The function to set the catalogues
 * @property {boolean} isAccountPending - Whether the account is pending
 * @property {boolean} is2faRequired - Whether 2FA is required
 * @property {() => void} handleLogout - The function to handle the logout
 */

export const AuthContext = React.createContext(
  /** @type {AuthContextValue} */ ({ user: null, userType: DEFAULT_USER_ROLE, isAuthLoading: true }),
);
