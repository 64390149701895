import React from 'react';

const EmailAuthCards = React.lazy(() => import('../../components/login/email-auth-container'));
const AccountCreation = React.lazy(() => import('../../components/AccountCreation/AccountCreation'));

/**
 * @satisfies {ExtendedRouteObject[]}
 */
export const unauthenticatedUserRoutes = [
  {
    name: 'Unauthenticated User',
    path: 'login',
    type: 'item',

    element: <EmailAuthCards quickLogin />,
  },
  {
    name: 'Pharmacy Creation',
    path: 'pharmacy-creation',
    type: 'item',
    element: <AccountCreation isPharmacy />,
  },
  {
    name: 'Doctor Creation',
    path: 'doctor-creation',
    type: 'item',
    element: <AccountCreation isDoctor />,
  },
  {
    name: 'Supplier Creation',
    path: 'supplier-creation',
    type: 'item',
    element: <AccountCreation isSupplier />,
  },
];
