import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';

/**
 * @satisfies {ExtendedRouteObject}
 */
export const catalogueRoutes = {
  name: 'Catalogue',
  path: 'catalogue',
  type: 'layout',
  element: Loadable(React.lazy(() => import('../../../components/catalogue/CataloguePageLayout'))),
  children: [
    {
      name: 'Catalogue Page',
      path: ':cataloguePage',
      type: 'item',
      element: Loadable(React.lazy(() => import('../../../components/catalogue/catalogue-container'))),
    },
  ],
};
