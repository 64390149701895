import React from 'react';
import { Grid2 as Grid, Container, CircularProgress } from '@mui/material';

/**
 * @param {Object} props
 * @param {Object} [props.sx]
 * @param {string | undefined} [props.debugSection]
 * @returns {React.ReactNode}
 */
const Loading = ({ sx, debugSection }) => (
  <Container maxWidth="md" sx={sx}>
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '80vh', ...sx }}
    >
      <CircularProgress />
      {debugSection && import.meta.env.DEV && <span>{debugSection}</span>}
    </Grid>
  </Container>
);

export default Loading;
