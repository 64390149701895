import { routes as allRoutes } from './routes';

/**
 * Recursively filters routes based on user access
 * @param {Object} params
 * @param {ExtendedRouteObject[]} params.routes
 * @param {Object} params.params
 * @param {string} params.params.userType
 * @returns {ExtendedRouteObject[]}
 */
const filterRoutes = ({ routes, params }) =>
  routes
    .filter((route) => !route.userCanAccess || route.userCanAccess(params))
    .map(({ children, index, ...route }) => {
      if (index) {
        return route;
      }

      return {
        ...route,
        children: children ? filterRoutes({ routes: children, params }) : undefined,
      };
    });

/**
 * @param {Object} params
 * @param {string} params.userType
 * @returns {ExtendedRouteObject[]}
 */
export const getRoutes = (params) => filterRoutes({ routes: allRoutes, params });
