import React from 'react';
import { ListItemText } from '@mui/material';

import { useAuth } from '../../../hooks/useAuth';
import { USER_TYPES, allowedUserType } from '../../../utils/constants';
import { Accordion } from './Accordion';
import { NavigationLink } from './NavigationLink';

const { PATIENT, PHARMACIST, SUPPLIER } = USER_TYPES;

export const AccountInfoMenuItems = ({ onClose }) => {
  const { user, userType } = useAuth();

  /**
   * @param {React.SyntheticEvent} event
   */
  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  if (!user) {
    return null;
  }

  return (
    <Accordion>
      <Accordion.Trigger title="Account" to="/account-details" onClick={handleOnClick} />

      <Accordion.Details>
        <NavigationLink to="/account-details/account-info" onClose={onClose}>
          <ListItemText style={{ paddingLeft: 20 }} primary="Account Info" />
        </NavigationLink>
        <NavigationLink to="/account-details/security" onClose={onClose}>
          <ListItemText style={{ paddingLeft: 20 }} primary="Security" />
        </NavigationLink>
        <NavigationLink
          to="/account-details/documents"
          onClose={onClose}
          userCanAccess={user && allowedUserType([PATIENT, PHARMACIST, SUPPLIER], userType)}
        >
          <ListItemText style={{ paddingLeft: 20 }} primary="Documents" />
        </NavigationLink>
      </Accordion.Details>
    </Accordion>
  );
};
