import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';

/**
 * @satisfies {ExtendedRouteObject[]}
 */
export const authenticatedPaymentsRoutes = [
  {
    name: 'Booking Payment Success',
    path: 'booking-payment-success',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../../components/landing-pages/booking-payment-success'))),
  },
  {
    name: 'Script Payment Success',
    path: 'script-payment-success',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../../components/landing-pages/script-payment-success'))),
  },
  {
    name: 'Payment Container',
    path: 'payment-container',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../../components/checkout/PaymentContainer'))),
  },
];
