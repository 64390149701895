import React from 'react';

export const useAccordion = () => {
  const [expanded, setExpanded] = React.useState(false);

  /**
   * @type {React.RefCallback<HTMLDivElement>}
   */
  const accordionRef = React.useCallback((node) => {
    if (!node) {
      return;
    }

    const hasCurrentPage = node.querySelector('[aria-current="page"]') !== null;

    setExpanded(hasCurrentPage);
  }, []);

  /**
   * @param {React.SyntheticEvent} _
   * @param {boolean} isExpanded
   */
  const handleOnChange = (_, isExpanded) => {
    setExpanded(isExpanded);
  };

  return { expanded, accordionRef, handleOnChange };
};
