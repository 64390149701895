import React from 'react';

import { authenticationRoutes, baseFormsRoutes } from './base-routes';
import { SharedBaseLayout } from '../layouts';

/**
 * These are the routes shared between all users:
 * - Unauthenticated users
 * - Authenticated users
 *   - Verified users
 *   - Unverified users
 * @satisfies {ExtendedRouteObject[]}
 */
export const sharedRoutes = [
  ...authenticationRoutes,
  {
    name: 'Shared Layout With Authentication Layout',
    type: 'layout',
    element: <SharedBaseLayout />,
    children: [...baseFormsRoutes],
  },
];
