import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';
import { allowedUserType, USER_TYPES } from '../../../utils/constants';

const { SUPPORT, NURSE, DOCTOR, DIRECTOR } = USER_TYPES;

/**
 * @satisfies {ExtendedRouteObject}
 */
export const patientsRoutes = {
  name: 'Patients',
  path: 'patients',
  type: 'layout',
  userCanAccess: ({ userType }) => allowedUserType([SUPPORT, NURSE, DOCTOR, DIRECTOR], userType),
  element: Loadable(React.lazy(() => import('../../../features/patients/pages/PatientsPageLayout'))),
  children: [
    {
      name: 'Patients',
      index: true,
      path: '',
      type: 'item',
      element: Loadable(
        React.lazy(() => import('../../../components/patient-management/PatientsPage/PatientsPageContainer')),
      ),
    },
    {
      name: 'Patient',
      path: ':id',
      type: 'item',
      element: Loadable(
        React.lazy(
          () => import('../../../components/patient-management/PatientPage/main-section/PatientPageContainer'),
        ),
      ),
    },
  ],
};
