import React from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';

/**
 * @param {import('@mui/material').AccordionDetailsProps} props
 */
export const Details = ({ children, style, ...props }) => (
  <AccordionDetails style={{ padding: 0, margin: 0, ...style }} {...props}>
    {children}
  </AccordionDetails>
);
