import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';

/**
 * @satisfies {ExtendedRouteObject[]}
 */
export const accountRoutes = [
  {
    name: 'Account',
    path: 'account-details',
    type: 'parent',
    children: [
      {
        name: 'Account Details',
        index: true,
        path: '',
        type: 'item',
        element: Loadable(
          React.lazy(() =>
            import('../../../components/account-details/AccountDetailsIndexPage').then((module) => ({
              default: module.AccountDetailsIndexPage,
            })),
          ),
        ),
      },
      {
        name: 'Account Details',
        path: ':accountDetailsPage',
        type: 'item',
        element: Loadable(
          React.lazy(() => import('../../../components/account-details/main-section/AccountDetailsContainer')),
        ),
      },
    ],
  },
  {
    name: 'Email Preferences',
    path: 'email-preferences',
    type: 'item',
    element: Loadable(React.lazy(() => import('../../../components/account-details/email-section/email-preferences'))),
  },
];
