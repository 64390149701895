import React from 'react';
import { Navigate } from 'react-router';

import { pharmaciesRoutes } from './pharmaciesRoutes';

/**
 * @satisfies {ExtendedRouteObject[]}
 */
export const pharmaciesRoutesWithLegacyRoute = [
  pharmaciesRoutes,
  {
    // This is a legacy route for the pharmacy directory - used to be /pharmacy-directory
    name: 'Pharmacy Directory',
    path: 'pharmacy-directory',
    type: 'item',
    element: <Navigate replace to="/pharmacies" />,
  },
];
