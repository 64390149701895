import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router';

import { useAuth } from '../hooks/useAuth';
import { Loading } from '../components/layout';
import EnforceTwoFactor from '../components/account-details/misc-section/enforce-two-factor';
import { getRoutes } from './getRoutes';

export const Router = () => {
  const { userType, isAuthLoading, is2faRequired, handleLogout } = useAuth();

  React.useEffect(() => {
    window.addEventListener('vite:preloadError', () => {
      window?.location?.reload?.();
    });

    return () => {
      window.removeEventListener('vite:preloadError', () => {
        window?.location?.reload?.();
      });
    };
  }, []);

  const router = React.useMemo(() => createBrowserRouter(getRoutes({ userType })), [userType]);

  if (isAuthLoading) {
    return <Loading sx={{ minHeight: '100vh' }} />;
  }

  if (is2faRequired) {
    return <EnforceTwoFactor handleLogout={handleLogout} />;
  }

  return <RouterProvider router={router} />;
};
