import React from 'react';
import { Loadable } from '../../../components/navigation/Loadable';

/**
 * @satisfies {ExtendedRouteObject}
 */
export const pendingAccountRoute = {
  name: 'Pending Account',
  path: 'pending-account',
  type: 'item',
  element: Loadable(React.lazy(() => import('../../../components/landing-pages/pending-account'))),
};
