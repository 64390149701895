import React from 'react';
import { ListItemText } from '@mui/material';

import { useAuth } from '../../../hooks/useAuth';
import { medicalInfoRoutes } from '../../../Router/routes/base-routes';
import { Accordion } from './Accordion';
import { NavigationLink } from './NavigationLink';

export const PatientMedicalInfoMenuItems = ({ onClose }) => {
  const { user, userType } = useAuth();

  /**
   * @param {React.MouseEvent<HTMLAnchorElement>} event
   */
  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  if (!user || !medicalInfoRoutes.userCanAccess({ userType })) {
    return null;
  }

  return (
    <Accordion>
      <Accordion.Trigger title="Medical Info" to="/medical-info" onClick={handleOnClick} />

      <Accordion.Details>
        <NavigationLink to="/medical-info/medications" onClose={onClose}>
          <ListItemText style={{ paddingLeft: 20 }} primary="Medications" />
        </NavigationLink>
        <NavigationLink to="/medical-info/conditions" onClose={onClose}>
          <ListItemText style={{ paddingLeft: 20 }} primary="Conditions" />
        </NavigationLink>
        <NavigationLink to="/medical-info/concession-cards" onClose={onClose}>
          <ListItemText style={{ paddingLeft: 20 }} primary="Concessions" />
        </NavigationLink>
      </Accordion.Details>
    </Accordion>
  );
};
