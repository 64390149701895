import React from 'react';
import { Accordion as MuiAccordion } from '@mui/material';

import { Trigger } from './Trigger';
import { useAccordion } from './useAccordion';
import { Details } from './Details';

/**
 * @param {Omit<import('@mui/material').AccordionProps, 'expanded' | 'onChange' | 'ref'>} props
 */
export const Accordion = ({ children, sx, ...props }) => {
  const { expanded, accordionRef, handleOnChange } = useAccordion();

  return (
    <MuiAccordion
      expanded={expanded}
      onChange={handleOnChange}
      elevation={0}
      sx={{
        margin: '0 !important',

        '&:before': { display: 'none' },
        '& .MuiAccordionSummary-root': {
          minHeight: '54px',
          maxHeight: '54px',
          height: '54px',
        },
        '& .MuiAccordionSummary-content': {
          margin: '0',
        },
        ...sx,
      }}
      ref={accordionRef}
      {...props}
    >
      {children}
    </MuiAccordion>
  );
};

Accordion.Trigger = Trigger;
Accordion.Details = Details;
