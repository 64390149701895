import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';
import { allowedUserType, USER_TYPES } from '../../../utils/constants';

const { NURSE, SUPPORT, DOCTOR, DIRECTOR, PHARMACIST, SUPPLIER } = USER_TYPES;

/**
 * @satisfies {ExtendedRouteObject}
 */
export const tgaRoutes = {
  name: 'TGA Files',
  path: 'tga',
  type: 'item',
  userCanAccess: ({ userType }) => allowedUserType([NURSE, SUPPORT, DOCTOR, DIRECTOR, PHARMACIST, SUPPLIER], userType),
  element: Loadable(React.lazy(() => import('../../../components/tga/tga-details-container'))),
};
