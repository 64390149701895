import React from 'react';
import { ListItemText } from '@mui/material';

import { useAuth } from '../../../hooks/useAuth';
import { Accordion } from './Accordion';
import { NavigationLink } from './NavigationLink';

export const CatalogueMenuItems = ({ onClose }) => {
  const { catalogues } = useAuth();

  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  if (catalogues?.length === 0) {
    return null;
  }

  return (
    <Accordion>
      <Accordion.Trigger title="Catalogue" to="/catalogue" onClick={handleOnClick} />

      <Accordion.Details>
        {catalogues.map((catalogue) => (
          <NavigationLink key={catalogue} to={`/catalogue/${catalogue}`} onClose={onClose}>
            <ListItemText style={{ paddingLeft: 20, textTransform: 'capitalize' }} primary={catalogue} />
          </NavigationLink>
        ))}
      </Accordion.Details>
    </Accordion>
  );
};
