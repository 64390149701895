import React from 'react';

const AuthLandingPageSuccess = React.lazy(
  () => import('../../../components/login/auth-landing-pages/auth-landing-page-success'),
);

const AuthLandingPageError = React.lazy(
  () => import('../../../components/login/auth-landing-pages/auth-landing-page-error'),
);

const ResetPasswordInput = React.lazy(
  () => import('../../../components/login/auth-landing-pages/reset-password-input'),
);

const AuthLandingPageContainer = React.lazy(
  () => import('../../../components/login/auth-landing-pages/auth-landing-page-container'),
);

const ResetPasswordLinkError = React.lazy(
  () => import('../../../components/login/auth-landing-pages/reset-password-link-error'),
);

/**
 * @satisfies {ExtendedRouteObject[]}
 */
export const authenticationRoutes = [
  {
    name: 'Auth Landing Page Success',
    path: 'auth-landing-page-success',
    type: 'item',
    element: <AuthLandingPageSuccess key="auth-landing-page-success" />,
  },
  {
    name: 'Auth Landing Page Error',
    path: 'auth-landing-page-error',
    type: 'item',
    element: <AuthLandingPageError key="auth-landing-page-error" />,
  },
  {
    name: 'Reset Password Input',
    path: 'reset-password-input',
    type: 'item',
    element: <ResetPasswordInput key="reset-password-input" />,
  },
  {
    name: 'Reset Password Link Error',
    path: 'reset-password-link-error',
    type: 'item',
    element: <ResetPasswordLinkError key="reset-password-link-error" />,
  },
  {
    name: 'Auth Landing Page',
    path: 'auth-landing-page',
    type: 'item',
    element: <AuthLandingPageContainer key="auth-landing-page" />,
  },
];
