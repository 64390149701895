import React from 'react';

import { Loadable } from '../../../components/navigation/Loadable';
import { allowedUserType, USER_TYPES } from '../../../utils/constants';

const { NURSE, DOCTOR, SUPPORT } = USER_TYPES;

/**
 * @satisfies {ExtendedRouteObject}
 */
export const assignedPatientsRoutes = {
  name: 'Assigned Patients',
  path: 'assigned-patients',
  type: 'item',
  userCanAccess: ({ userType }) => allowedUserType([NURSE, DOCTOR, SUPPORT], userType),
  element: Loadable(
    React.lazy(() => import('../../../components/patient-management/assigned-patients/assigned-patients-container')),
  ),
};
